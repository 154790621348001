import { useTranslation } from "next-i18next"
import { IconCreatePost } from "../icon-tsx/IconCreatePost"
import { IconCart } from "../icon-tsx/IconCart"
import { useRouter } from "next/router"

export const CreateTicketArea = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const onNavigate = () => {
    router.push("/help-center/submit-ticket")
  }

  if (["/help-center/submit-ticket"].includes(router.pathname)) {
    return null
  }

  return (
    <div
      className={`px-[16px] xs:px-[12px] xs:py-[16px] py-[24px] rounded-l-[24px] fixed right-0 top-[42%] z-10 bg-bg-button dark:bg-bg-button/10 cursor-pointer xs:hover:pl-[85px] hover:pl-[106px] cart-container overflow-hidden`}
      onClick={onNavigate}
    >
      <div className="relative flex items-center gap-[8px] xs:text-[12px] text-[14px] Nunito-700 text-main-01 whitespace-nowrap">
        <p
          className={`absolute xs:-left-[78px] -left-[92px] top-[50%] translate-y-[-50%] -translate-x-[10px] cart-text`}
        >
          {t("help_center.send_message_support")}
        </p>
        <IconCreatePost
          className="ml-auto mr-0 xs:w-[24px] xs:h-[18px] w-[32px] h-[24px]"
          fill={"#7673E6"}
        />
      </div>
    </div>
  )
}
