import { AppContext } from "@/app/context"
import { useContext } from "react"

export const IconCreatePost = ({
  className,
  fill
}: {
  className?: string
  fill?: string
}) => {
  const { themeApp } = useContext(AppContext)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8492 1.06424C11.6015 0.311921 12.8213 0.311921 13.5736 1.06424L14.9358 2.42642C15.6881 3.17874 15.6881 4.39848 14.9358 5.1508L13.728 6.35856C13.6332 6.31161 13.5334 6.26085 13.43 6.20639C12.7338 5.83988 11.9077 5.32547 11.2911 4.70891C10.6746 4.09235 10.1602 3.26621 9.79364 2.57002C9.73918 2.46658 9.6884 2.36681 9.64145 2.27199L10.8492 1.06424ZM10.4072 5.5928C11.1368 6.32239 12.0647 6.89662 12.7996 7.28701L8.05003 12.0365C7.7553 12.3313 7.37291 12.5225 6.96028 12.5814L4.09969 12.9901C3.464 13.0809 2.91913 12.536 3.00994 11.9003L3.41859 9.03972C3.47754 8.62708 3.66873 8.2447 3.96347 7.94997L8.713 3.20044C9.10339 3.93529 9.67762 4.86318 10.4072 5.5928ZM1.125 14.25C0.779822 14.25 0.5 14.5298 0.5 14.875C0.5 15.2202 0.779822 15.5 1.125 15.5H14.875C15.2202 15.5 15.5 15.2202 15.5 14.875C15.5 14.5298 15.2202 14.25 14.875 14.25H1.125Z"
        fill={fill ? fill : "#FAF8FF"}
      />
    </svg>
  )
}
